<template>
  <Layout>
    <slice-zone
      v-if="content?.data?.slices"
      :slices="content.data.slices"
      :components="components"
      :context="content.data"
    />
  </Layout>
</template>

<script setup>
import { useStore } from 'vuex';
import { onBeforeUnmount, defineAsyncComponent } from 'vue';
import { components } from '~/slices';
import getHead from '@/utils/get-head';
import setHead from '@/utils/set-head';
import setLisaAutoFill from '@/utils/set-lisa-auto-fill';
import getAltlangs from '@/utils/get-alternate-lang-data';
import setAlternateLanguages from '@/utils/set-alternate-languages';
import { isProRoute } from '@/utils/helpers/get-is-pro-route';
import resetTopHeader from '@/utils/reset-top-header';
import {
  routerLinks,
  guideLinks,
  articleLinks,
  offerLinks,
  productLinks,
  regionLinks,
  countryLinks,
  topHeaderLinks,
} from '~/utils/fetch-links';
import {
  LAYOUT_SET_FOOTER_VARIANT,
  LAYOUT_SET_HEADER_LITE,
  LAYOUT_SET_BREADCRUMBS,
  TRACKING_SET_DATA,
} from '@/store/mutationTypes';
import {
  useRoute,
  usePrismic,
  useAsyncData,
  createError,
  useI18n,
  useNuxtApp,
  useHead,
} from '#imports';

const Layout = defineAsyncComponent(() => import('@/components/layout'));

const i18n = useI18n();
const { language: lang } = i18n.localeProperties.value;
const route = useRoute();
const { client: $prismic } = usePrismic();
const store = useStore();
const isPro = isProRoute(route);
const pageUID = isPro ? 'login-pro' : 'login';
const context = useNuxtApp();

const page = await useAsyncData('login', async () => {
  const prismicPage = await $prismic.getByUID('page_user_login', pageUID, {
    fetchLinks: [
      ...routerLinks,
      ...offerLinks,
      ...regionLinks,
      ...countryLinks,
      ...guideLinks,
      ...articleLinks,
      ...productLinks,
      ...topHeaderLinks,
    ],
    lang,
  });

  return prismicPage;
});

const { data } = page;
const content = page.data._value;

// handle error
if (content === undefined) {
  throw createError({ statusCode: 404 });
}

// Get alternate language pages data
const altLangs = await getAltlangs(page, $prismic);

// Set alternate language pages data
await setAlternateLanguages(altLangs?.results, store);

// Set Head
setHead(content?.data, store);
setLisaAutoFill(data, store, i18n);
resetTopHeader(content?.data?.slices, store);

// setUrl({ route }, page);

store.commit(`layout/${LAYOUT_SET_BREADCRUMBS}`, [
  {
    label: i18n.t('breadcrumbs.home'),
    cta: {
      href: '/',
    },
  },
  {
    label: i18n.t('breadcrumbs.login'),
  },
]);

// Tracking
store.commit(`tracking/${TRACKING_SET_DATA}`, { pageType: content.type });

useHead(getHead(context, [], { hasOrganization: true }));

onBeforeUnmount(() => {
  store.commit(`layout/${LAYOUT_SET_HEADER_LITE}`, false);
  store.commit(`layout/${LAYOUT_SET_FOOTER_VARIANT}`, null);
});
</script>
